<template>
  <generic-card
      @click="$emit('click')"
      @delete="$emit('deleteSalary')"
      :allow-delete="$can('manage', 'all')"
  >

    <template #iconContent>
      <b-avatar
          size="lg"
          variant="light-primary"
          :text="nameInitials"
          class="my-auto"
      />
    </template>

    <template #bodyContent>
      <h4>{{ reactiveDisplay }}</h4>

      <div class="text-truncate text-primary d-flex">
        <div style="width: 30px;"
             class="mx-auto">
          <icon icon="calendar-day"/>
        </div>
        <div class="w-100">{{ salary.month|moment('MMMM YYYY') }}</div>
      </div>
      <div class="text-truncate text-warning d-flex">
        <div style="width: 30px;"
             class="mx-auto">
          <icon icon="user"/>
        </div>
        <div class="w-100">{{ currency(salary.net) }}</div>
      </div>
      <div class="text-truncate text-success d-flex">
        <div style="width: 30px;"
             class="mx-auto">
          <icon icon="umbrella-beach"/>
        </div>
        <div class="w-100">{{ salary.paidVacationRemaining ? salary.paidVacationRemaining : 0 }}
          {{ $tc('day', salary.paidVacationRemaining) }}
        </div>
      </div>
    </template>
  </generic-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency, capitalize } from '@/utils/filter'

import GenericCard from './Card'

export default {
  components: {
    GenericCard
  },
  props: {
    salary: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const nameInitials = computed(() => {
      let fullName = JSON.parse(JSON.stringify(props.salary.employee.firstName)) + ' ' + JSON.parse(JSON.stringify(props.salary.employee.name))
      let initials = fullName.split(' ')

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }

      return initials.toUpperCase()
    })

    const reactiveDisplay = computed(() => {
      return props.salary.employee.firstName + ' ' + props.salary.employee.name
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,
      capitalize,

      // Data

      // Computed
      nameInitials,
      reactiveDisplay,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>