<template>
  <div>

    <search-filter
        :items="salaries"
        :keyTitleForAlphabeticalSort="'employee'"
        :additionalSortOptions="additionalSortOptions"
        :selectedView="'list'"
        :excludedKeys="['salary']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        :allowAdd="$can('manage', 'all')"
        :allowAddByUpload="$can('manage', 'all')"
        @columnClick="$can('manage', 'all')?selectSalary($event):downloadSalary($event)"
        @addItem="salariesModalShow=true"
        @addItemByUpload="salaryByUploadModalShow=true"
    >
      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-salary
            :salary="item"
            @click="$can('manage', 'all')?selectSalary(item):downloadSalary(item)"
            @deleteSalary="$can('manage', 'all')?deleteSalaryAlert(item):false"
        />
      </template>

      <!--      List view-->

      <!--      Cell employee-->
      <template #listView_cell_employee="{item}">
        {{ item.employee.firstName }} {{ item.employee.name }}
      </template>

      <!--      Cell month-->
      <template #listView_cell_month="{item}">
        {{ item.month|moment('MMMM YYYY') }}
      </template>

      <!--      Cell paid by employer-->
      <template #listView_cell_paidByEmployer="{item}">
        {{ currency(item.paidByEmployer) }}
      </template>

      <!--      Cell net-->
      <template #listView_cell_net="{item}">
        {{ currency(item.net) }}
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Edit-->
          <button-edit
              v-if="$can('manage', 'all')"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              @click.native.stop="selectSalary(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadSalary(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <!--          Delete-->
          <button-delete
              v-if="$can('manage', 'all')"
              @click.native.stop="deleteSalaryAlert(item)"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>

    </search-filter>

    <!-- modal salary by upload-->
    <modal-upload-salary
        :singleFile.sync="newSalaryFile"
        :documentType="'salary'"
        :title="capitalize($t('salary'))"
        :isOpen.sync="salaryByUploadModalShow"

    />

    <!-- modal salaries-->
    <modal-salaries
        :salaries.sync="currentSalaries"
        :title="salaryModalTitle"
        :isOpen.sync="salariesModalShow"
        @submitValidated="submitValidatedSalariesLocal(currentSalaries)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinSalaries } from './mixinSalaries'

import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'
import ButtonDownload from '@/components/button/Download'

export default {
  components: {
    ButtonEdit,
    ButtonDelete,
    ButtonDownload
  },
  mixins: [mixinSalaries],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>